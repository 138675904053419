<template>
  <div>
    <header class="header">
      <div class="header-text">付款给 {{ payOrderInfo.mchName }}</div>
      <div class="header-img">
        <img :src="avatar ? avatar : icon_member_default" alt="" />
      </div>
    </header>
    <div class="content">
      <div class="warp" v-if="payOrderInfo.ext && payOrderInfo.ext.name">
        <div class="label">用户姓名：</div>
        <div class="value">{{ payOrderInfo.ext.name }}</div>
      </div>
      <div class="warp">
        <div class="label">商品描述：</div>
        <div class="value">{{ payOrderInfo.subject }}</div>
      </div>
      <div class="warp">
        <div class="label">订单号：</div>
        <div class="value">{{ payOrderInfo.mchOrderNo }}</div>
      </div>
    </div>
    <div class="plus-input">
      <!-- ￥字符 货币的符号-->
      <div class="S">
        <img src="../../assets/icon/S.svg" alt="" />
      </div>

      <!-- 手写输入框 -->
      <div class="input-c">
        <div class="input-c-div-1">{{ payOrderInfo.amount }}</div>
      </div>
      <!-- 手写输入框的提示文字 -->
      <div v-show="!payOrderInfo.amount" class="placeholder">请输入金额</div>
    </div>
  </div>
</template>

<script>
import { getPayPackage } from "@/api/api";
import config from "@/config";
export default {
  props: {
    payOrderInfo: {
      type: Object,
      default: () => { }
    }
  },
  data: function () {
    return {
      avatar: require("../../assets/images/zfb.jpeg"), // 商户头像默认
    };
  },
  methods: {
    //小程序使用  ALI_APP 支付
    //非小程序使用 ALI_JSAPI 支付
    //PC使用 ALI_PC
    pay: function (payOrderNo, wayCode) {
      let that = this;
      this.$loading.show({
        backgroundColor: "rgba(0, 0, 0, 0)",
      });
      getPayPackage(payOrderNo, wayCode)
        .then((res) => {
          //订单创建异常
          if (res.code != "0") {
            return alert(res.message);
          }

          // if (res.data.orderState != 1) {
          //   //订单不是支付中，说明订单异常
          //   return alert(res.data.errMsg);
          // }

          if (!window.AlipayJSBridge) {
            // alert('AlipayJSBridgeReady');
            document.addEventListener(
              "AlipayJSBridgeReady",
              function () {
                that.doAlipay(res.data.payData.tradeNo, payOrderNo);
              },
              false
            );
          } else {
            that.doAlipay(res.data.payData.tradeNo, payOrderNo);
          }
        })
        .catch((err) => {
          that.$router.push({
            name: config.errorPageRouteName,
            params: { errInfo: err.message },
          });
        })
        .finally(() => {
          this.$loading.hide();
        });
    },

    doAlipay(tradeNo, payOrderNo) {
      let that = this;

      // eslint-disable-next-line no-undef
      window.AlipayJSBridge.call(
        "tradePay",
        {
          tradeNO: tradeNo,
        },
        function (data) {
          if ("9000" == data.resultCode) {
            // alert('支付成功！'); 

            //‘8000’：后台获取支付结果超时，暂时未拿到支付结果;
            //‘6004’：支付过程中网络出错， 暂时未拿到支付结果;
          } else if ("8000" == data.resultCode || "6004" == data.resultCode) { 
            alert("正在处理中!");
          } else if ("6001" == data.resultCode) { 
            alert("支付未完成!"); 
          } else {
            ///其他异常信息， 需要取消订单
            alert("用户已取消！");
            // window.AlipayJSBridge.call("closeWebview");
          }
          that.$router.push({
            name: "ReturnAlipay",
            query: { payOrderNo: payOrderNo },
          });
        }
      );
    },
  },
};
</script>
<style lang="css" scoped>
@import "./pay.css";
</style>
