<template>
  <div>
    <header class="header">
      <div class="header-text">付款给{{ payOrderInfo.mchName }}</div>
      <div class="header-img">
        <img :src="avatar ? avatar : icon_member_default" alt="" />
      </div>
    </header>
    <div class="content">
      <div class="warp" v-if="payOrderInfo.ext && payOrderInfo.ext.name">
        <div class="label">用户姓名：</div>
        <div class="value">{{ payOrderInfo.ext.name }}</div>
      </div>
      <div class="warp">
        <div class="label">商品描述：</div>
        <div class="value">{{ payOrderInfo.subject }}</div>
      </div>
      <div class="warp">
        <div class="label">订单号：</div>
        <div class="value">{{ payOrderInfo.mchOrderNo }}</div>
      </div>
    </div>
    <div class="plus-input">
      <!-- ￥字符 货币的符号-->
      <div class="S">
        <img src="../../assets/icon/S.svg" alt="" />
      </div>
      <div class="input-c">
        <div class="input-c-div-1">{{ payOrderInfo.amount }}</div>
        <!-- 数字金额后边的光标 -->
        <!-- <div class="input-c-div" style="background:#07c160"></div> -->
      </div>
      <!-- 手写输入框的提示文字 -->
      <div v-show="!payOrderInfo.amount" class="placeholder">请输入金额</div>
    </div>
  </div>
</template>

<script>
import { getPayPackage } from "@/api/api";
import config from "@/config";
import wayCodeUtils from "@/utils/wayCode";
export default {
  props: {
    payOrderInfo: {
      type: Object,
      default: () => { }
    }
  },
  data: function () {
    return {
      avatar: require("../../assets/images/wx.png"), // 商户头像默认
      resData: {},
    };
  },
  methods: {
    //小程序使用  WX_LITE 支付
    //非小程序使用 WX_JSAPI 支付
    //PC使用 WX_BAR
    // 支付事件
    pay: function (payOrderNo, wayCode) {
      // 该函数执行效果慢
      let that = this;
      this.$loading.show({
        backgroundColor: "rgba(0, 0, 0, 0)",
      });
      getPayPackage(payOrderNo, wayCode)
        .then((res) => {
          if (res.code != "0") {
            return alert(res.message);
          }

          // if (res.data.orderState != 1) {
          //   //订单不是支付中，说明订单异常
          //   return alert(res.data.errMsg);
          // }

          that.resData = res.data.payData;
          if (typeof WeixinJSBridge == "undefined") {
            if (document.addEventListener) {
              document.addEventListener(
                "WeixinJSBridgeReady",
                that.onBridgeReady(payOrderNo),
                false
              );
            } else if (document.attachEvent) {
              document.attachEvent("WeixinJSBridgeReady", that.onBridgeReady(payOrderNo));
              document.attachEvent("onWeixinJSBridgeReady", that.onBridgeReady(payOrderNo));
            }
          } else {
            that.onBridgeReady(payOrderNo);
          }
        })
        .catch((err) => {
          that.$router.push({
            name: config.errorPageRouteName,
            params: { errInfo: err.message },
          });
        })
        .finally(() => {
          this.$loading.hide();
        });
    },

    /* 唤醒微信支付*/
    onBridgeReady(payOrderNo) {
      let that = this;

      if (wayCodeUtils.getPayWay().mini) {
        let payDataStr = JSON.stringify({ payData: that.resData, returnUrl: that.payOrderInfo.returnUrl, orderNo: that.payOrderInfo.orderNo });//因为要吧参数传递给小程序，所以这里需要转为字符串
        const url = `../payment/wxpay?payDataStr=${encodeURIComponent(payDataStr)}`;
        // eslint-disable-next-line no-undef
        wx.miniProgram.redirectTo({
          url: url
        });
      } else {
        try {
          // let params = Object.assign(that.resData, { appId: that.resData.appid });

          // eslint-disable-next-line no-undef
          WeixinJSBridge.invoke("getBrandWCPayRequest", that.resData, function (res) {
            if (res.err_msg == "get_brand_wcpay_request:ok") {
              console.log(res);
              // 使用以上方式判断前端返回,微信团队郑重提示：
              //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
              // //重定向
              // if (that.payOrderInfo.returnUrl) {
              //   location.href = that.payOrderInfo.returnUrl;
              // } else {
              //   alert("支付成功！");
              //   window.WeixinJSBridge.call("closeWindow");
              // }
            } else if (res.err_msg == "get_brand_wcpay_request:cancel") {
              alert("支付取消");
              // window.WeixinJSBridge.call("closeWindow");
            } else if (res.err_msg == "get_brand_wcpay_request:fail") {
              alert("支付失败:" + JSON.stringify(res));
              // window.WeixinJSBridge.call("closeWindow");
            } else if (res.err_msg == "total_fee") {
              alert("缺少参数");
              // window.WeixinJSBridge.call("closeWindow");
            } else {
              alert(JSON.stringify(res));
            }
            that.$router.push({
              name: "ReturnWxpay",
              query: { payOrderNo: payOrderNo },
            });
          });
        } catch (error) {
          console.log(error);
        }
      }


    },
  },
};
</script>
<style lang="css" scoped>
@import "./pay.css";
</style>
